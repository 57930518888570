module.exports = [{
      plugin: require('C:/OSPanel/domains/gatsby-si/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"softindustry.by","short_name":"softindustry","start_url":"/","background_color":"#009AD3","theme_color":"#009AD3","display":"minimal-ui","icon":"src/data/images/softindustry-icon.png"},
    },{
      plugin: require('C:/OSPanel/domains/gatsby-si/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KHRSDMP","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('C:/OSPanel/domains/gatsby-si/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
